import "regenerator-runtime/runtime";
import "core-js/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import {Root} from "./Root";

const mountElt = document.getElementById("root");

if (process.env.NODE_ENV !== "production" && module.hot) {
    console.log("Enabling hot-reload of React componentry");
    module.hot.accept("./Root", () => {
        const newRootModule = require("./Root");
        const NewRoot = newRootModule["default"];
        ReactDOM.render(
            <NewRoot />,
            mountElt
        );
    });
}

ReactDOM.render(
    <Root />,
    mountElt
);
