import Observable from "zen-observable";
import * as ajax from "./api";

function isHttpError(statusCode) {
    return statusCode >= 400 && statusCode <= 599;
}

function isNetworkError(statusCode) {
    return statusCode === 0;
}

export function requestPasswordReset(emailOrUsername,  origin) {
    return ajax.post("/api/user/request-password-reset", { emailOrUsername, origin, reason: 'USER_REQUESTED' });
}

export function setPassword(token, password, passwordConfirmation) {
    return new Observable(observer => {
        return ajax.post("/api/user/set-password", { token, password, passwordConfirmation }).subscribe(
            result => {
                observer.next({ success: true });
            },
            error => {
                let errors;
                if (error.code === 422) {
                    errors = JSON.parse(error.xhr.response).errors;
                }
                else if (isHttpError(error.code)) {
                    errors = ["Unable to set the password due to an unexpected error."];
                }
                else if (isNetworkError(error.code)) {
                    errors = ["Unable to set the password due to connectivity issues. Please try again."];
                }
                else {
                    errors = ["Unable to set the password due to an unexpected `${error.code}` error."];
                }
                observer.next({ success: false, errors });
                observer.complete();
            }
        );
    });
}

export function requestCheckTokenIsValid(token) {
    return ajax.get(`/api/user/validate-token?token=${token}`);
}
