import nanoajax from "nanoajax";
import Observable from "zen-observable";
import clientAppVersion from "./appVersion";

export class HttpError extends Error {
    constructor(code, xhr) {
        super(`HTTP error: ${code}`);
        this.code = code;
        this.xhr = xhr;
    }
}

function emitResponse(code, responseText, xhr, observer) {
    if (code >= 200 && code < 300 || code === 1223) {
        if (code === 200) {
            const contentType = xhr.getResponseHeader("Content-Type");
            const data = contentType === "application/json" ? JSON.parse(responseText) : responseText;
            observer.next({ data, code, xhr });
        }
        else {
            observer.next({ data: null, code: code === 1223 ? 204 : code, xhr });
        }
        observer.complete();
    }
    else {
        observer.error(new HttpError(code, xhr));
    }
}

function observeHttp(nanoOptions) {
    return new Observable(observer => {
        let pending = true;
        const xhr = nanoajax.ajax(nanoOptions, (code, responseText, xhr) => {
            pending = false;
            emitResponse(code, responseText, xhr, observer);
        });

        return () => {
            if (pending) xhr.abort();
        }
    });
}

const defaultHeaders = {
    "Accept": "application/json",
    "X-Accounts-Version": clientAppVersion,
    "X-Requested-With": "XMLHttpRequest"
};

export function get(url) {
    return observeHttp({
        url,
        headers: defaultHeaders
    });
}

export function post(url, data) {
    return observeHttp({
        url,
        method: "POST",
        headers: { ...defaultHeaders, "Content-Type": "application/json" },
        body: JSON.stringify(data)
    });
}
