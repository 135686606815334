import React from "react";
import styled from "styled-components";

export const H1 = styled.h1`
color: #28313E;
font-weight: bold;
margin: 0;
padding: 10px;
font-size: 1em;
border-bottom: 1px solid #ddd;
background-color: #eee;
`;

export const MainSection = styled.div`
background-color: white;
border-radius: 3px;
border: 1px solid #ddd;
box-shadow: 0 0 5px rgba(0,0,0,0.1);
color: #28313E;
margin: 0 auto;
`;

export const MainContent = styled(MainSection)`
max-width: 680px;
margin-top: 20px;
`;

export const VerticalFormBase = styled.input`
display: block;
margin: 10px 0;
width: 100%;
`;

export const VerticalFormTextInput = styled(VerticalFormBase)`
border: 1px solid #ccc;
border-radius: 4px;
box-shadow: inset 0 1px 3px #ddd;
box-sizing: border-box;
color: inherit;
display: inline-block;
font: inherit;
letter-spacing: normal;
line-height: normal;
margin: 0;
padding: .5em .6em;
text-rendering: auto;
vertical-align: middle;
word-spacing: normal;
zoom: 1;
`;

export const VerticalFormButton = styled(VerticalFormBase)`
background-color: #0078e7;
border: none transparent;
border-radius: 4px;
box-sizing: border-box;
color: #fff;
cursor: pointer;
display: inline-block;
font-family: inherit;
font-size: 100%;
line-height: normal;
margin: 0;
padding: .5em 1em;
text-align: center;
text-decoration: none;
user-select: none;
white-space: nowrap;
vertical-align: middle;
zoom: 1;
`;

export const ErrorMessage = styled.div`
color: rgb(217, 83, 79);
`;
