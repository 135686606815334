import React, {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {ErrorMessage, H1, MainContent, VerticalFormButton} from "./PageContent";
import {requestCheckTokenIsValid, setPassword as sendSetPassword} from "./passwordResetActions";
import * as queryString from './query-string';
import {useSingleSubscriptionHolder} from "./SubscriptionHolder";
import {useLocationSearchParams} from "./LocationSearchParams";

const DisplayState = {
    INVALID_TOKEN: 'INVALID_TOKEN',
    PASSWORD_FORM: 'PASSWORD_FORM',
    SUCCESSFULLY_CHANGED_PASSWORD: 'SUCCESSFULLY_CHANGED_PASSWORD'
};

const PasswordInput = ({attribute, onAttributesChange, children, value}) => {
    const onChange = useCallback(event => {
        event.preventDefault();
        onAttributesChange(attribute, event.target.value);
    }, [onAttributesChange, attribute]);
    return (
        <div style={{marginBottom: ".5em"}}>
            <Label htmlFor={attribute}>{children}</Label>
            <Input id={attribute}
                   type="password"
                   value={value}
                   onChange={onChange}
            />
        </div>
    );
};

function advanceDisplayStateTo(nextDisplayState) {
    return (prevState) => (prevState !== DisplayState.SUCCESSFULLY_CHANGED_PASSWORD ? nextDisplayState : prevState);
}

const PasswordReset = ({}) => {
    const [token, redirect, origin] = useLocationSearchParams(({token, redirect, origin}) => [token, redirect, origin]);
    const [displayState, setDisplayState] = useState(DisplayState.PASSWORD_FORM);
    const [errors, setErrors] = useState([]);
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const registerSetPasswordSubscription = useSingleSubscriptionHolder();
    const onAttributesChanged = useCallback((attribute, value) => {
        switch (attribute) {
            case "password":
                setPassword(value);
                break;
            case "passwordConfirmation":
                setPasswordConfirmation(value);
                break;
        }
    }, []);
    const handleSubmit = useCallback(() => {
        registerSetPasswordSubscription(sendSetPassword(token, password, passwordConfirmation).subscribe(
            ({success, errors}) => {
                if (success) {
                    setDisplayState(DisplayState.SUCCESSFULLY_CHANGED_PASSWORD);
                    window.setTimeout(() => {
                        window.location.replace(redirect)
                    }, 5000);
                } else if (errors.includes("INVALID_TOKEN")) {
                    console.error("invalid token found", errors);
                    setDisplayState(advanceDisplayStateTo(DisplayState.INVALID_TOKEN));
                } else {
                    setErrors(errors);
                }
            }
        ));
    }, [token, password, passwordConfirmation, redirect]);
    useEffect(() => {
        const tokenCheckSubscription = requestCheckTokenIsValid(token).subscribe(
            result => {
                setDisplayState(advanceDisplayStateTo(DisplayState.PASSWORD_FORM));
            },
            error => {
                console.error("Failed to validate token", error);
                setDisplayState(advanceDisplayStateTo(DisplayState.INVALID_TOKEN));
            }
        );
        return () => {
            tokenCheckSubscription.unsubscribe();
        }
    }, []);

    switch (displayState) {
        case DisplayState.INVALID_TOKEN:
            return <InvalidToken origin={origin}/>;
        case DisplayState.PASSWORD_FORM:
            return <PasswordForm handleSubmit={handleSubmit} onAttributesChanged={onAttributesChanged} errors={errors}
                                 password={password} passwordConfirmation={passwordConfirmation}/>;
        case DisplayState.SUCCESSFULLY_CHANGED_PASSWORD:
            return <SuccessfullyChangedPassword redirectUrl={redirect}/>;
    }
};

const PasswordForm = ({handleSubmit, onAttributesChanged, errors, password, passwordConfirmation}) => {
    const onSubmit = useCallback(event => {
        event.preventDefault();
        handleSubmit();
    }, [handleSubmit]);
    return (
        <MainContent>
            <form className="x-change-password-form" onSubmit={onSubmit}>
                <H1>Enter and confirm your password</H1>

                <Section>
                    <PasswordInput attribute="password" value={password}
                                   onAttributesChange={onAttributesChanged}>Password</PasswordInput>
                    <PasswordInput attribute="passwordConfirmation" value={passwordConfirmation}
                                   onAttributesChange={onAttributesChanged}>Re-enter password</PasswordInput>
                </Section>

                <Section visible={errors.length > 0}>
                    {errors.map((e, index) => <ErrorMessage key={index} className="x-error-message">{e}</ErrorMessage>)}
                </Section>

                <Section>
                    <VerticalFormButton className="x-submit"
                                        type="submit"
                                        value="Set password"/>
                </Section>
            </form>
        </MainContent>
    );
};

const InvalidToken = ({origin}) => (
    <MainContent>
        <H1>Invalid password reset request</H1>

        <Section>
            The link you clicked may have expired, or already been used.
            Please <Link to={{pathname: "/request-password-reset", search: queryString.stringify({origin})}}
                         className="x-redirect-link">request a new one here</Link>.
        </Section>
    </MainContent>
);

const SuccessfullyChangedPassword = ({redirectUrl}) => (
    <MainContent>
        <H1>Your password has been successfully updated</H1>

        <Section className="x-response-message">
            Sending you back to <a href={redirectUrl}>the login page</a>...
        </Section>

    </MainContent>
);

const Label = styled.label`
text-align: right;
display: inline-block;
vertical-align: middle;
width: 10em;
margin: 0 1em 0 0;
`;

const Input = styled.input`
padding: .5em .6em;
display: inline-block;
border: 1px solid #ccc;
border-radius: 4px;
box-shadow: inset 0 1px 3px #ddd;
box-sizing: border-box;
vertical-align: middle;
`;

const Section = styled.div`
padding: 10px;
display: ${({visible = true}) => visible ? "block" : "none"};
`;

export default PasswordReset;
