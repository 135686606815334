import {useCallback, useEffect, useRef} from "react";

export function useSingleSubscriptionHolder(inputs = []) {
    const subscriptionRef = useRef(null);
    useEffect(() => {
        return () => {
            if (subscriptionRef.current)
                subscriptionRef.current.unsubscribe();
        };
    }, inputs);

    return useCallback(subscription => {
        if (subscriptionRef.current) {
            subscriptionRef.current.unsubscribe();
        }
        subscriptionRef.current = subscription;
        return subscription;
    }, []);
}
