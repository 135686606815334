import React, {useCallback, useState} from "react";
import {H1, MainContent, VerticalFormButton, VerticalFormTextInput} from "./PageContent";
import {requestPasswordReset} from "./passwordResetActions";
import {useSingleSubscriptionHolder} from "./SubscriptionHolder";
import {useLocationSearchParams} from "./LocationSearchParams";
import styled from "styled-components";

const RequestPasswordSuccess = () => (
    <MainContent>
        <H1>New password requested</H1>

        <Section>
            <span className="x-response-message">
                Thank you for your request. An email should be on its way to you.
                If for any reason you do not receive it, please contact your system administrator or <a href="mailto:tisupport@timgroup.com">tisupport@timgroup.com</a>.
            </span>
        </Section>
    </MainContent>
);

const RequestPasswordForm = ({initialEmailOrUsername, onSubmit}) => {
    const [emailOrUsername, setEmailOrUsername] = useState(initialEmailOrUsername);

    function _handleSubmit(event) {
        event.preventDefault();
        onSubmit(emailOrUsername);
    }

    return (
        <MainContent>
            <form className="x-request-password-reset-form" onSubmit={_handleSubmit}>
                <H1>Please enter your email address or username to reset your password</H1>

                <Section extraSpaceBelow={true}>
                    <VerticalFormTextInput className="x-email-input"
                           type="text"
                           placeholder="Email or username"
                           autoComplete="off"
                           autoCapitalize="off"
                           autoCorrect="off"
                           spellCheck={false}
                           value={emailOrUsername}
                           onChange={evt => setEmailOrUsername(evt.target.value)}/>
                </Section>

                <Section>
                    <VerticalFormButton className="x-submit"
                                        type="submit"
                                        value="Submit"/>
                </Section>
            </form>
        </MainContent>
    );
};

const RequestPasswordReset = ({}) => {
    const [origin, initialEmailOrUsername] = useLocationSearchParams(({origin = "", emailOrUsername = ""}) => [origin, emailOrUsername]);
    const [submitted, setSubmitted] = useState(false);
    const registerSubscription = useSingleSubscriptionHolder();
    const handleSubmit = useCallback(submittedEmailOrUsername => {
        registerSubscription(requestPasswordReset(submittedEmailOrUsername, origin).subscribe(
            () => {
                setSubmitted(true);
            },
            error => {
                console.error("Error submitting password reset", error);
                setSubmitted(true);
            }
        ));
    }, [origin, registerSubscription]);

    if (submitted)
        return <RequestPasswordSuccess/>;
    else
        return <RequestPasswordForm onSubmit={handleSubmit} initialEmailOrUsername={initialEmailOrUsername}/>;
};

const Section = styled.div`
padding: 10px;
display: ${({visible = true}) => visible ? "block" : "none"};
margin-bottom: ${({extraSpaceBelow = false}) => extraSpaceBelow ? ".5em" : "inherit"};
`;

export default RequestPasswordReset;
