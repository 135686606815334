import React from "react";
import {Route, Switch} from "react-router-dom";
import styled from "styled-components";
import RequestPasswordReset from "./RequestPasswordReset";
import PasswordReset from "./PasswordReset";

const timIdeasLogo = process.env.IS_IN_WEBPACK ? require("../images/tim-logo-header.png") : "";

const Header = styled.header`
background-color: #28313E;
padding: 10px;
`;

const Logo = styled.img`
width: 128px;
height: 46px;
`;

export const App = () => (
    <div>
        <Header>
            <Logo src={timIdeasLogo}/>
        </Header>
        <div>
            <Switch>
                <Route exact path="/request-password-reset">
                    <RequestPasswordReset/>
                </Route>
                <Route exact path="/password-reset">
                    <PasswordReset/>
                </Route>
            </Switch>
        </div>
    </div>
);
